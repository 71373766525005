































































































































































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import Modal from '@/components/common/Modal'
import equipmentDrawer from './equipmentDrawer.vue'
import BindAreadialog from './BindAreaDialog.vue'
import _ from 'lodash'
import { ElTable } from 'element-ui/types/table'
import DeviceInfo from '@/views/AdminManagement/RegionManagement/EquipPage/deviceInfo.vue'
import { is } from '@/utils/helpers'
import PageTitle from '@/components/common/pageTitle.vue'
import asyncPool from '@/utils/async-pool'
import batchDeleteAllSuccess from '@/utils/batchDeleteAllSuccess'

const drawer = Modal(equipmentDrawer)

@Component({ components: { PageTitle, DeviceInfo, BindAreadialog } })
export default class EquipmentList extends Vue {
  @Ref('table') table: ElTable
  @Ref() readonly DeviceInfoNode: DeviceInfo
  @Ref() readonly editDialogNode: BindAreadialog

  typeList: AnyObj[] = []
  filterData: AnyObj = {}
  pagination = { pageSize: 20, currentPage: 1 }
  loading = false
  equipmentList: AnyObj[] = []
  equipmentTotal = 0
  statistic: any = {}
  selection: AnyObj[] = []

  get typeOptions() {
    return this.typeList.map(({ id, name }: AnyObj) => ({ value: id, label: name }))
  }
  get isChildRoute() {
    return this.$route.name === '设备配置'
  }

  get params() {
    const { currentPage, pageSize } = this.pagination
    const { types } = this.filterData
    const params: AnyObj = {
      offset: (currentPage - 1) * pageSize,
      limit: pageSize
    }
    if (types) params.types = [types]
    return {
      ...params,
      ..._.pickBy(_.omit(this.filterData, ['types']), is.ava)
    }
  }

  @Watch('pagination', { deep: true })
  async watchPagination() {
    await this.fetchEquipmentList()

    if (this.table) {
      this.table.bodyWrapper.scrollTop = 0
    }
  }

  mounted() {
    this.fetchEquipmentTypes()
    this.fetchEquipmentList()
    this.getStatistic()
    drawer.on('created', (arg: any) => {
      const h = this.$createElement
      this.$msgbox({
        title: '设备创建成功',
        message: h('div', {}, [
          h(
            'p',
            { style: 'text-align: center;color:var(--color-primary' },
            '如需为该设备绑定区域则点击“绑定区域”按钮 '
          ),
          h(
            'p',
            { style: 'text-align: center;color:var(--color-primary' },
            '填写完成后即可将设备绑定到指定区域 '
          ),
          h(
            'p',
            { style: 'text-align: center;color:var(--color-primary' },
            '若不需要请点击“忽略”按钮 '
          )
        ]),
        showCancelButton: true,
        confirmButtonText: '绑定区域',
        cancelButtonText: '忽略',
        center: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.editDialogNode.open('add', arg.id)
            done()
          } else {
            done()
          }
        }
      }).catch(() => false)
      this.fetchEquipmentList()
    })
  }
  batchDelete() {
    if (this.selection.length) {
      this.$confirm('即将批量删除设备，删除后无法恢复，是否确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        loading: true,
        onConfirm: async () => {
          const res = await asyncPool(this.selection, ({ id }: any) =>
            this.$api.base.deleteDevice(id)
          )
          if (batchDeleteAllSuccess(res)) {
            this.$message({ message: '删除成功', type: 'success' })
          }

          await this.fetchEquipmentList()
        }
      }).catch(() => false)
    }
  }
  selectionChange(selection: AnyObj[]) {
    this.selection = selection
  }
  getStatistic() {
    Promise.all([
      this.fetchStatistic({}),
      this.fetchStatistic({ active: true }),
      this.fetchStatistic({
        bind: true
      })
    ]).then(res => {
      this.statistic = {
        total: res[0],
        online: res[1],
        offline: res[0] - res[1],
        bind: res[2]
      }
    })
  }
  async fetchStatistic(val?: AnyObj) {
    const params = {
      offset: 0,
      limit: 1,
      ...val
    }
    const { data } = await this.$api.base.getDevicesSearch(params)
    if (data.code === 0) {
      return data.count
    }
    return 0
  }

  async fetchEquipmentList() {
    this.loading = true
    const { data } = await this.$api.base.fetchDevicesDetailSearch(this.params)
    this.loading = false
    if (data.code === 0) {
      this.equipmentList = data.data
      this.equipmentTotal = data.count
    }
  }

  async fetchEquipmentTypes() {
    const { data } = await this.$api.base.getDeviceTypes()

    if (data.code === 0) {
      this.typeList = data.data
    }
  }

  filter() {
    this.pagination.currentPage = 1
    this.fetchEquipmentList()
  }

  addEquipment() {
    drawer.open()
  }

  configEquipment(device: any) {
    localStorage.setItem('device-type', device.type)
    localStorage.setItem('device-id', device.id)
    localStorage.setItem('device-physicalId', device.physicalId)
    this.$router.push({
      params: { id: device.id },
      name: '设备配置'
    })
  }

  seeEquipment({ physicalId, locationName }: AnyObj) {
    this.DeviceInfoNode.open({ physicalId, locationName })
  }

  deleteEquipment(row: AnyObj) {
    this.$confirm('即将删除设备，删除后无法恢复，是否确定删除？', {
      type: 'warning',
      loading: true,
      onConfirm: async () => {
        const { data }: any = await this.$api.base.deleteDevice(row.id)
        if (!data?.error) {
          this.$message({ message: '删除成功', type: 'success' })
          await this.fetchEquipmentList()
        }
      }
    }).catch(() => {})
  }
}
