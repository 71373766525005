










































































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import FormDrawer from '@/components/common/FormDrawer/index.vue'
import { ElForm } from 'element-ui/types/form'
import validator from '@/components/common/validator'
import _ from 'lodash'

@Component({ components: { FormDrawer } })
export default class EquipmentDrawer extends Vue {
  @Prop({ default: false }) visible: boolean
  @Ref('form') form: ElForm

  data() {
    return {
      narIdValue: localStorage.getItem('nvrId')
    }
  }
  formData: AnyObj = {
    physicalId: '',
    description: null,
    nvrId: '',
    channelId: '',
    longitude: '',
    latitude: ''
  }
  rules = {
    physicalId: [{ required: true, message: '请输入硬件编码', trigger: 'blur' }],
    channelId: [
      {
        validator: (rule: any, value: any, callback: any) => {
          if (value && !/^[1-9]$|^[12][0-9]$|^3[0-2]$/.test(value)) {
            callback(new Error('通道号应为1～32之间的整数值'))
          }
          callback()
        },
        trigger: 'blur'
      }
    ],
    longitude: [{ validator: validator.longitude({}), trigger: 'blur' }],
    latitude: [{ validator: validator.latitude({}), trigger: 'blur' }]
  }
  loading = false

  mounted() {
    this.initialize()
    this.changeRules({})
  }

  changeRules({ validate = false }) {
    this.rules.latitude = [
      {
        validator: validator.latitude({ type: !this.formData.longitude }),
        trigger: 'blur'
      }
    ]
    this.rules.longitude = [
      {
        validator: validator.longitude({ type: !this.formData.latitude }),
        trigger: 'blur'
      }
    ]
    if (validate) this.form.validate()
  }

  initialize() {}
  change() {
    this.$forceUpdate()
  }
  // el-input 在组件中形成了深层嵌套，出现错误
  handleCommand(value: string) {
    this.$forceUpdate()
    this.formData.nvrId = value
  }

  close() {
    this.form.resetFields()
    this.formData = {}
    this.visible = false
  }

  submit() {
    this.form.validate(async valid => {
      if (valid) {
        this.loading = true
        const { physicalId, longitude, latitude, description, channelId, nvrId } = this.formData
        const coordinates = longitude && latitude ? longitude + ',' + latitude : null
        const param = _.pickBy({
          physicalId,
          coordinates,
          description,
          channelId: Number(channelId),
          nvrId
        })
        const { data } = await this.$api.base.addDevice(param)
        this.loading = false
        if (data.code === 0) {
          this.$emit('created', data.data)
          this.close()
        }
      }
    })
    if (this.formData.nvrId && this.formData.nvrId.trim().length > 0) {
      localStorage.setItem('nvrId', this.formData.nvrId)
      this.narIdValue = localStorage.getItem('nvrId')
    }
  }
}
