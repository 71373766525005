














import { Vue, Component } from 'vue-property-decorator'
import ComponentIndex from '../../EquipmentConfig/Component/index.vue'

@Component({
  name: 'DeviceInfo',
  components: { ComponentIndex }
})
export default class DeviceInfo extends Vue {
  private visible = false
  infos: AnyObj = {}
  open(device: any) {
    localStorage.setItem('device-type', device.type)
    localStorage.setItem('device-id', device.id)
    localStorage.setItem('device-physicalId', device.physicalId)
    this.visible = true
  }
}
