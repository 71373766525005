




















































import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { judgeValue } from '@/utils'
import InputSearch from '@/components/common/InputSearch.vue'
import { ElForm } from 'element-ui/types/form'
import _ from 'lodash'
import store, { actionTypes } from '@/store'
@Component({
  name: 'BindAreaDialog',
  components: { InputSearch }
})
export default class BindAreaDialog extends Vue {
  @Ref() readonly editTreeNode: Vue
  @Ref() readonly formNode: ElForm
  @Ref() inputSearch: InputSearch
  private filterText: string | null = ''
  private showFlag = false
  private loading = false
  private selectArea: any[] = []
  private ruleForm: any = {
    checkId: '',
    deviceId: ''
  }
  private rules = {
    checkId: [{ required: true, message: '请选择所属区域', trigger: 'blur' }]
  }
  private areaLoading = false
  mode = ''
  show = true

  get user() {
    return this.$store.state.user || {}
  }

  @Watch('showFlag')
  watchFlag() {
    if (!this.showFlag) {
      this.formNode.resetFields()
    }
  }
  deactivated() {
    this.showFlag = false
  }

  private watchInput(val: string) {
    if (val === '') {
      this.filterText = null
      this.editTreeNode.search(val)
    }
  }
  private searchArea(val: string) {
    this.filterText = val
    if (this.filterText === null || this.filterText.match(/^[ ]*$/)) {
      return false
    } else {
      this.editTreeNode.search(this.filterText)
    }
  }

  private cancelDialog() {
    this.showFlag = false
  }
  private message(msg: string, type: MessageType) {
    this.$message({
      message: msg,
      type: type
    })
  }
  private getList(list: any[]) {
    list.map(e => {
      if (e.children) {
        e.children = this.getList(e.children)
      }
    })
    return list
  }
  public init(list: any) {
    const data = _.cloneDeep(list)
    if (data && data[0]) {
      data[0].disabled = true // 根区域下不能直接新建点位
    }
    this.selectArea = this.getList(data)
    this.selectArea.pop()
  }
  private async getAreasList() {
    this.areaLoading = true
    const areas = await store.dispatch(actionTypes.FETCH_BASE_AREAS)
    if (areas) {
      const arrList = Array.from(areas)
      arrList.push(areas)
      this.init(arrList)
    }
    this.areaLoading = false
  }
  public dataInit() {
    this.selectArea = []
    this.filterText = '' // 每次打开都清空搜索框中的内容
    this.ruleForm.checkId = ''
    this.editTreeNode.setCheckedKeys([])
    this.inputSearch.restore()
  }
  public async open(type: string, deviceId: any) {
    if (this.selectArea.length === 0) {
      this.getAreasList()
    }
    this.showFlag = true
    this.mode = type
    this.$nextTick(() => {
      if (this.inputSearch) this.inputSearch.restore()
      if (type === 'add') {
        this.ruleForm.deviceId = deviceId
        this.dataInit()
      }
    })
  }
  private submit() {
    this.formNode.validate(async valid => {
      if (valid) {
        this.loading = true
        let res
        const Location: any = {
          areaId: this.ruleForm.checkId
        }
        judgeValue(Location)
        if (this.mode === 'add') {
          try {
            res = await this.$api.base.bindDevice(this.ruleForm.deviceId, Location.areaId)
            if (res.data && res.data.code === 0) {
              this.message('绑定成功', 'success')
              this.$emit('refresh')
              this.dataInit()
              this.showFlag = false
            } else {
              this.loading = false
              return false
            }
          } catch (err) {
            this.loading = false
          }
        }
      }
    })
  }
  private setChecked(ids: any[] = []) {
    this.$nextTick(() => {
      this.editTreeNode.setCheckedKeys(ids)
    })
  }
  private change(item: AnyObj, node: boolean) {
    if (node == true || this.ruleForm.checkId == item.id) {
      this.ruleForm.checkId = item.id
      this.setChecked([item.id])
    }
  }
  private treeClick({ id, disabled }: any) {
    if (!disabled) {
      this.ruleForm.checkId = id
      this.setChecked([id])
    }
  }
  private filter(value: string, data: AnyObj) {
    if (!value) return true
    return data.name.indexOf(value) !== -1
  }
}
